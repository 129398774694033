import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CampaignsBpmsService {

  // servicio falso que replica el consumo de un servicio externo que alimenta a bpms y consulta
  constructor() { }

  campaings = campaings;

  getCampaings(){
    return this.campaings;
  }
}
const campaings = [
  {
    id: 1,
    name: 'Recursos Humanos'
  },
  {
    id: 2,
    name: 'Nomina'
  },
  {
    id: 3,
    name: 'Administración'
  }
];
